import React from 'react'
import WorkflowCards from '../cards/workflowcards'
import './scss/workflow.css'
import {IoIosPeople} from 'react-icons/io'
import {GrLaunch} from 'react-icons/gr'
import { ImPencil2 } from "react-icons/im";
import { SiDevdotto } from "react-icons/si";
import Arrow from '../assets/arrow.png'
 
function Workflow(){
    return(
        <div>
            <div className="work-flow">
            <p className="workflow">Workflow</p>
            <div className="work">
                <WorkflowCards
                    img={<IoIosPeople size={50} alt="Plan-Icons"/>}
                    title = "Planning"
                />
                <div className="arrow">
                    <img src={Arrow} alt="arrow-right"/>
                </div>
                <WorkflowCards
                    img={<ImPencil2 size={50} alt="Design-Dots"/>}
                    title = "Designing"
                />
                <div className="arrow">
                    <img src={Arrow} alt="arrow-right"/>
                </div>
                <WorkflowCards
                    img={<SiDevdotto size={50} alt="Dev-Dots"/>}
                    title = "Development"
                />
                <div className="arrow">
                    <img src={Arrow} alt="arrow-right"/>
                </div>
                <WorkflowCards
                    img={<GrLaunch size={50} alt="Launch-Icon"/>}
                    title = "Production"
                />
            </div>
        </div>
        </div>
    )
}
export default Workflow