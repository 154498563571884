import React from 'react'
import './scss/projects-card.css'


function ProjectsCard (props) {
    return(
        <div className="Proj-Cards">
            <div className="card-img">
                <img src={props.image}/>
                <div className="card-title"><p>{props.title}</p></div>
            </div>
            
            <div className="card-desc"><p>{props.description}</p></div>
        </div>
    )
}
export default ProjectsCard