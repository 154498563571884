import React,{useEffect} from 'react'
import './scss/projects.css'
import ProjectsCard from '../cards/projects-card'
import Web from '../assets/wall.jpg'
import Tanvi from '../assets/projects/tanvifoods.png'
import Samsiddhi from '../assets/projects/samsiddhi.png'
import Ivarna from '../assets/projects/ivarna.png'
import CINEMAWALA from '../assets/projects/cinemawala.png'
import AVASHYAA from '../assets/projects/avashyaa.png'
import MODERNMART from '../assets/projects/modern_mart.png'
import Footer from '../footer/footer'

function Projects() {

    
    
    function menu() {
        var x = document.getElementById("dropheader");
        var x1 = document.getElementById("hamburger");
        var x2 = document.getElementById("hamburger-close");
        var y = document.getElementById("social-icons");
        if(window.innerWidth<1200){
            if (x.style.display === "block") {
                x.style.display = "none";
                // y.style.display = "none";
                x2.style.display = "none";
                x1.style.display = "block";
            } else {
                x.style.display = "block";
                // y.style.display = "block";
                x2.style.display = "block";
                x1.style.display = "none";
            }
        }
    }

    useEffect(()=>{
        menu()
    },[])

    return(
        <div className="main-project">
            <div className="proj-main-title">
                <p>Our Projects</p>
            </div>
            <div className="proj-cards">
                <ProjectsCard
                    image = {Tanvi}
                    title="TANVI FOODS"
                    description = "Designed for the Tanvi Foods, which vitally deals with Packaged and Frozen foods. We built a fully functioning web application. Corresponding to the application, it comes loaded with attractive UI, which helped them increase the flow of customers. It built using React Js holding the application both ways."
                />
                <ProjectsCard
                    image = {CINEMAWALA}
                    title="CINEMAWALA"
                    description = "It’s user designed application for a client who’s been working with us for a while. This application comes handy when you’ve difficulty in planning varied stuff when you’re shooting a film/ad. With a top-notch interface covering a wide range of aspects to sort out props, locations, cast, and fine tuning the script. It also saves you from the fuss of organising the scenes in your fashion of working."
                />
                <ProjectsCard
                    image = {Samsiddhi}
                    title="KLH SAMSIDDHI"
                    description = "Samsiddhi is a web application designed specifically for the needs of KL University, Hyderabad. its superior UI and Design is an added advantage for the users. It comes with fully stacked Flutter framework."
                />
                <ProjectsCard
                    image = {Ivarna}
                    title="KLH IVARNA"
                    description = "A web application that is custom build for the easy moving of the KL University, Hyderabad’s college fest. This application stands apart from all you’ve seen earlier with the improved UI and animations. The payment gateway integration advances with loaded security for seamless transactions."
                />
                <ProjectsCard
                    image = {AVASHYAA}
                    title="AVASHYAA"
                    description = "We all look for alternatives, don’t we? Here’s one. Avashyaa. An application which could satisfy your needs in delivering your groceries of your liking in your locality. If you are wondering the difference between the other apps, well, you can order your list through WhatsApp. Isn’t that feasible? It comes with Flutter Framework, REST additionally stacked with Firebase. It’s presently working in Isnapur, Hyderabad."
                />
                <ProjectsCard
                    image = {MODERNMART}
                    title="MODERN MART"
                    description = "Heard of Walmart? This is Modern Mart. A web application on similar grounds with Big Basket and other competitors in the market, but it offers to place your orders through WhatsApp. A nice and modern touch, isn’t it? Built using Flutter, REST, Firebase and it’s integrated with Razorpay to ease the transaction process.  It offers its service in Nandigama, Telangana."
                />
            </div>
            
           <div>
           <Footer/>
           </div>
        </div>
    )
}

export default Projects