// PoliciesPage.js
import React, { useState } from 'react';
import './policiesPage.css';
import Footer from '../footer/footer.js';

function PoliciesPage() {
    const [activeSection, setActiveSection] = useState(null);

    const toggleSection = (section) => {
        if (activeSection === section) {
            setActiveSection(null);
        } else {
            setActiveSection(section);
        }
    };

    return (
        <div>
            <div className="policies-page">

                <h1>Our Policies</h1>

                {/* <section>
                    <h2 onClick={() => toggleSection('shipping')}>Shipping & Delivery</h2>
                    {activeSection === 'shipping' && (
                        <div className="content">
                            <p>
                                <strong>Shipping Methods:</strong> We offer standard and expedited shipping options. You can select your preferred shipping method at checkout.
                            </p>
                            <p>
                                <strong>Shipping Costs:</strong> Shipping costs are calculated based on the weight of the package and the destination. You will see the shipping cost at checkout before you place your order.
                            </p>
                            <p>
                                <strong>Estimated Delivery Times:</strong> Standard shipping typically takes 5-7 business days. Expedited shipping options are available for faster delivery.
                            </p>
                            <p>
                                <strong>Order Tracking:</strong> Once your order has been shipped, you will receive an email with a tracking number and a link to track your package.
                            </p>
                        </div>
                    )}
                </section> */}

                <section>
                    <h2 onClick={() => toggleSection('privacy')}>Privacy Policy</h2>
                    {activeSection === 'privacy' && (
                        <div className="content">
                            <p>
                                <strong>Information Collection:</strong> We collect personal information that you provide to us when you create an account, place an order, or contact us.
                            </p>
                            <p>
                                <strong>Use of Information:</strong> We use your information to process your orders, improve our services, and communicate with you.
                            </p>
                            <p>
                                <strong>Data Security:</strong> We implement security measures to protect your information. However, no method of transmission over the Internet or electronic storage is 100% secure.
                            </p>
                            <p>
                                <strong>Third-Party Services:</strong> We do not share your personal information with third parties except as necessary to provide our services or as required by law.
                            </p>
                        </div>
                    )}
                </section>

                <section>
                    <h2 onClick={() => toggleSection('return')}>Return & Refund</h2>
                    {activeSection === 'return' && (
                        <div className="content">
                            <p>
                                We want you to be completely satisfied with your purchase. If you are not satisfied, you may return the item for a refund or exchange within 30 days of purchase. The item must be in its original condition and packaging.
                            </p>
                            <p><strong>Return Process:</strong></p>
                            <ul>
                                <li>Contact our customer service team to initiate a return.</li>
                                <li>We will provide you with a return authorization and instructions.</li>
                                <li>Ship the item back to us using the provided instructions.</li>
                                <li>Once we receive the item, we will inspect it and process your refund or exchange.</li>
                            </ul>
                            <p><strong>Timeline:</strong></p>
                            <ul>
                                <li><strong>Return Request:</strong> Initiate within 30 days of purchase.</li>
                                <li><strong>Return Shipping:</strong> Ship the item within 7 days of receiving the return authorization.</li>
                                <li><strong>Inspection and Refund Processing:</strong> We will inspect the item and process your refund or exchange within 5 business days of receiving the returned item.</li>
                                <li><strong>Refund Completion:</strong> Refunds will be issued to the original payment method within 7-10 business days after the inspection is complete.</li>
                            </ul>
                        </div>
                    )}
                </section>

                {/* <section>
                    <h2 onClick={() => toggleSection('return')}>Return & Refund</h2>
                    {activeSection === 'return' && (
                        <div className="content">
                            <p>
                                <strong>Return Policy:</strong> You can return most items within 30 days of receipt for a full refund. Items must be in their original condition and packaging.
                            </p>
                            <p>
                                <strong>Refund Process:</strong> To initiate a return, please contact our customer service team. Once your return is received and inspected, we will process your refund within 5-7 business days.
                            </p>
                            <p>
                                <strong>Exchanges:</strong> If you need to exchange an item, please contact our customer service team. We will assist you with the exchange process.
                            </p>
                            <p>
                                <strong>Exceptions:</strong> Some items are non-returnable, such as customized products. Please check the product description for details.
                            </p>
                        </div>
                    )}
                </section> */}

                {/* <section>
                        <h2>Refunds and Returns</h2>
                        <p>
                        We want you to be completely satisfied with your purchase. If you are not satisfied, you may return the item for a refund or exchange within 30 days of purchase. The item must be in its original condition and packaging.
                        </p>
                        <p><strong>Return Process:</strong></p>
                        <ul>
                        <li>Contact our customer service team to initiate a return.</li>
                        <li>We will provide you with a return authorization and instructions.</li>
                        <li>Ship the item back to us using the provided instructions.</li>
                        <li>Once we receive the item, we will inspect it and process your refund or exchange.</li>
                        </ul>
                        <p><strong>Timeline:</strong></p>
                        <ul>
                        <li><strong>Return Request:</strong> Initiate within 30 days of purchase.</li>
                        <li><strong>Return Shipping:</strong> Ship the item within 7 days of receiving the return authorization.</li>
                        <li><strong>Inspection and Refund Processing:</strong> We will inspect the item and process your refund or exchange within 5 business days of receiving the returned item.</li>
                        <li><strong>Refund Completion:</strong> Refunds will be issued to the original payment method within 7-10 business days after the inspection is complete.</li>
                        </ul>
                    </section> */}

                <section>
                    <h2 onClick={() => toggleSection('terms')}>Terms & Conditions</h2>
                    {activeSection === 'terms' && (
                        <div className="content">
                            <center><p>
                                <strong>For CONFEGURE TECHSOLS PRIVATE LIMITED</strong>
                            </p></center>
                            <p>
                                <strong>Acceptance of Terms:</strong> By using our website and services, you agree to be bound by these terms and conditions.
                            </p>
                            <p>
                                <strong>Use of Services:</strong> You agree to use our services only for lawful purposes and in accordance with these terms.
                            </p>
                            <p>
                                <strong>Account Responsibilities:</strong> You are responsible for maintaining the confidentiality of your account information and for all activities that occur under your account.
                            </p>
                            <p>
                                <strong>Intellectual Property:</strong> All content on our website, including text, graphics, and logos, is the property of our company and is protected by intellectual property laws.
                            </p>
                            <p>
                                <strong>Limitation of Liability:</strong> Our company is not liable for any direct, indirect, incidental, or consequential damages arising from your use of our services.
                            </p>
                        </div>
                    )}
                </section>

            </div>
            <div>
                <Footer />
            </div>
        </div>
    );
}

export default PoliciesPage;
