import React from 'react'
import './scss/aboutus.css'
import ACards from '../cards/about-cards'
import Footer from '../footer/footer'
import SUMANTH from '../assets/team/sumanth.jpg'
import SREERAM from '../assets/team/sreeram.jpg'
import SaiChinmay from '../assets/team/saichinmay.jpg'
import AravindKumar from '../assets/team/aravindkumar.jpg'
import PramodKumar from '../assets/team/pramodkumar.jpg'
import ManojKumar from '../assets/team/manojkumar.jpg'
import SaiHruthik from '../assets/team/saihruthik.jpg'
import Shiva from '../assets/team/shiva.jpg'
import Lokesh from '../assets/team/lokes.jpg'
import CustomerTestimony from '../assets/customer_testimony.jpg'


function About() {



    function menu() {
        var x = document.getElementById("dropheader");
        var x1 = document.getElementById("hamburger");
        var x2 = document.getElementById("hamburger-close");
        if (window.innerWidth < 1200) {
            if (x.style.display === "block") {
                x.style.display = "none";
                // y.style.display = "none";
                x2.style.display = "none";
                x1.style.display = "block";
            } else {
                x.style.display = "block";
                // y.style.display = "block";
                x2.style.display = "block";
                x1.style.display = "none";
            }
        }
    }

    React.useEffect(() => {
        menu()
    }, [])
    return (
        <div className="about-main">
            <div className="about-us-container">
                <center><h1>About Us</h1></center>
                <p><b>Company Overview:</b> Confegure Techsols is a Software Development Company based in Hyderabad, India. Started in 2021, we have achieved a strong reputation for delivering high-quality software solutions to clients across various industries. Our team of experienced developers, designers, and project managers is dedicated to providing innovative and effective solutions that meet our clients' needs and exceed their expectations.</p>
                <center><h2>Our Mission</h2></center>
                <p>Our mission at Confegure Techsols is to empower businesses through innovative technology solutions. We strive to be a trusted partner for our clients, helping them leverage the latest technologies to achieve their goals and stay ahead in today's competitive market.
                    <br /><br />We are committed to delivering value to our clients by providing cutting-edge solutions that are tailored to their unique needs. Our team of experts works tirelessly to stay at the forefront of technology trends and developments, ensuring that we can deliver the most effective solutions to our clients.
                    <br /><br />At Confegure Techsols, we also believe in giving back to the community. We are dedicated to using our skills and resources to make a positive impact on society and to support initiatives that promote education, sustainability, and social responsibility.
                    <br /><br />Overall, our mission is to be a catalyst for positive change, helping businesses thrive in the digital age and making a difference in the world around us.</p>
                <center><h2>Our Journey</h2></center>
                <p>2019 - In the bustling city of Hyderabad, a spark ignited in the minds of Sumanth Bejugam and team. Confegure Techsols was born, and our journey began with a vision to innovate and inspire.</p>
                <p>2021 - Fueled by our passion and dedication, we took a significant step forward and officially registered as a Private Limited company. This milestone marked a new chapter in our story, filled with possibilities and opportunities.</p>
                <p>2022 - The dawn of a new era! We proudly launched Tic Trac, our flagship product, and witnessed its phenomenal success. With over 17,000 downloads and a stellar 4.8-star rating, Tic Trac has become a game-changer in the industry, setting new standards of excellence.</p>
                <p>Our journey is a testament to our commitment to innovation, quality, and customer satisfaction. As we continue to grow and evolve, we remain steadfast in our mission to empower businesses and individuals through technology.</p>
                <center><h2>Our Team</h2></center>
                <div className="ACards-1">
                    <div className="team-cards">
                        <ACards
                            img={SUMANTH}
                            name="SUMANTH BEJUGAM"
                            desg="CEO"
                            facebook="https://www.facebook.com/"
                            instagram="https://www.instagram.com/"
                            linkedin="https://www.linkedin.com/in/bejugamsumanth"
                        />
                        <ACards
                            img={SaiHruthik}
                            name="SAI HRUTHIK BOJJA"
                            desg="Developer"
                            facebook="https://www.facebook.com/"
                            instagram="https://www.instagram.com/"
                            linkedin="https://www.linkedin.com/"
                        />
                        <ACards
                            img={SaiChinmay}
                            name="SAI CHINMAY TRIPURARI"
                            desg="Developer"
                            facebook="https://www.facebook.com/"
                            instagram="https://www.instagram.com/"
                            linkedin="https://www.linkedin.com/"
                        />
                        <ACards
                            img={AravindKumar}
                            name="ARAVIND KUMAR VEMULA"
                            desg="Developer"
                            facebook="https://www.facebook.com/"
                            instagram="https://www.instagram.com/"
                            linkedin="https://www.linkedin.com/"
                        />
                        <ACards
                            img={PramodKumar}
                            name="PRAMOD KUMAR KANIGIRI"
                            desg="Developer"
                            facebook="https://www.facebook.com/"
                            instagram="https://www.instagram.com/"
                            linkedin="https://www.linkedin.com/"
                        />
                        <ACards
                            img={ManojKumar}
                            name="MANOJ KUMAR"
                            desg="Developer"
                            facebook="https://www.facebook.com/"
                            instagram="https://www.instagram.com/"
                            linkedin="https://www.linkedin.com/"
                        />
                        <ACards
                            img={Shiva}
                            name="SHIVA SAI KRISHNA"
                            desg="Designer"
                            facebook="https://www.facebook.com/"
                            instagram="https://www.instagram.com/"
                            linkedin="https://www.linkedin.com/"
                        />
                        <ACards
                            img={Lokesh}
                            name="LOKESH"
                            desg="Designer"
                            facebook="https://www.facebook.com/"
                            instagram="https://www.instagram.com/"
                            linkedin="https://www.linkedin.com/"
                        />
                        <ACards
                            img={SREERAM}
                            name="SREE RAM TEJA.A"
                            desg="Designer"
                            facebook="https://www.facebook.com/"
                            instagram="https://www.instagram.com/"
                            linkedin="https://www.linkedin.com/"
                        />
                        <ACards
                            name="LEELA SAI KRISHNA VAMSHI"
                            desg="Designer"
                            facebook="https://www.facebook.com/"
                            instagram="https://www.instagram.com/"
                            linkedin="https://www.linkedin.com/"
                        />
                    </div>
                </div>
                <h2>Our Values</h2>
                <ul>
                    <li>Quality</li>
                    <li>Integrity</li>
                    <li>Innovation</li>
                    <li>Customer Satisfaction</li>
                </ul>
                <h2>Accomplishments</h2>
                <p>2022 - Acheived four first 10K downloads.</p>
                <h2>Customer Testimonials</h2>
                <img src={CustomerTestimony}/>
            </div>
            
            <div>
                <Footer />
            </div>
        </div>
    )
}
export default About