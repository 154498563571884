import './scss/cards.css'
function Cards(props) {
    
    return (
        <div className="Cards">
            <div className="card-img">
                <img src={props.img}/>
            </div>
            <div className="card-title"><p>{props.title}</p></div>
            <div className="card-desc"><p>{props.desc}</p></div>
        </div>
    )
}

export default Cards;