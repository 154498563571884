// src/pages/PricingPage.js
import React from 'react';
import { NavLink } from 'react-router-dom';
import './pricingpage.css';

function PricingPage() {
  return (
    <div className="pricing-container">
      <h1>Pricing</h1>
      <div className="pricing-table">
        <div className="pricing-card">
          <h2>Web Development</h2>
          <p className="price">Rs.30000*</p>
          <ul>
            <li>Custom Website Design</li>
            <li>Responsive Layout</li>
            <li>SEO Optimization</li>
            <li>Up to 10 Pages</li>
            <li>1 Month Free Maintenance</li>
          </ul>
          <NavLink to="/contactus" className="btn">Choose Plan</NavLink>
        </div>
        <div className="pricing-card">
          <h2>App Development</h2>
          <p className="price">Rs.45000*</p>
          <ul>
            <li>iOS and Android Apps</li>
            <li>Custom UI/UX Design</li>
            <li>Backend Integration</li>
            <li>Push Notifications</li>
            <li>3 Months Free Maintenance</li>
          </ul>
          <NavLink to="/contactus" className="btn">Choose Plan</NavLink>
        </div>
        <div className="pricing-card">
          <h2>Hybrid Development</h2>
          <p className="price">Contact Us</p>
          <ul>
            <li>All Web Development Features</li>
            <li>All App Development Features</li>
            <li>Support</li>
            <li>Updates</li>
            <li>Assistance</li>
          </ul>
          <NavLink to="/contactus" className="btn">Choose Plan</NavLink>
        </div>
      </div>
    </div>
  );
}

export default PricingPage;
