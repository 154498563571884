import React from 'react'
import './scss/footer.css'
import { NavLink } from 'react-router-dom';
import Logo from '../assets/confegure_logo_white.png'

function Footer()
{
    return(
        <div className="footer">
            <div className="first">
            <img src={Logo}/>
                <p className="heading">
                    Confegure Techsols Private Limited <br/>
                    Hyderabad, India
                </p>
                <p className="heading">
                    confegure@gmail.com
                </p>
                <p className="heading">
                    <NavLink to="/policies" className="footer-link">
                        Shipping & Delivery, Privacy Policy, Return & Refund, Terms & Conditions
                    </NavLink>
                </p>
            </div>
        </div>
    )
}

export default Footer