import './scss/expertise.css'
import React from 'react'
import Cards from '.././cards/card.js'
import Web from '../assets/website.png'
import App from '../assets/app.png'
import Ai from '../assets/ai.png'
import Cloud from '../assets/cloud.png'
import RPA from '../assets/rpa.png'
import Design from '../assets/design.png'
import SEO from '../assets/seo.png'
import IOT from '../assets/iot.png'


class expertise extends React.Component{
    
    
      
    render(){
        return(
            <div className="expert">
                <p className="expertise">Expertise...</p>
                <div className="main-cards">
                  <div className="cards">
                      <Cards
                      img={Web}
                      title="Website Development"
                      desc="Web applications are treated with utmost care in the developmental process and by the virtue of it, we'd be providing superlative assistance. "
                      />
                      <Cards
                      img={App}
                      title="Mobile App Development"
                      desc="Our vital area of expertise lies in the development of software applications. Developing Mobile applications which are subtle and user-friendly, added integration of world-class UI is our significant forte."
                      />
                      <Cards
                      img={Ai}
                      title="Machine Learning & AI"
                      desc="We come in handy with creating rich variants of ML and AI projects to help your business grow. "
                      />
                      <Cards
                      img={Cloud}
                      title="Cloud Computing"
                      desc="With cloud services on the rise across the globe, we associate ourselves with assistance to your organization in this area. As all the enterprises in the world see the growing benefits and their impact in production and efficiency on their application when using cloud services."
                      />
                      <Cards
                      img={RPA}
                      title="Robotic Process Automation"
                      desc="Having trouble with high-volume tasks? We tend to deliver top-notch automation software connected to various industrial-related errands to help you tackle them with ease. Well, robots are reliable, aren’t they?"
                      />
                      <Cards
                      img={Design}
                      title="Design & Animations"
                      desc="Do you want to attract an audience? We customize a myriad range of stunning promos, exuberant posters, and logos to widen your market to impress customers in choosing your brand."
                      />
                      <Cards
                      img={SEO}
                      title="SEO & Digital Marketing"
                      desc="We bestow professional services in Digital Marketing that help you advertise through SEO, Website Strategy, Social Media Marketing etc."
                      />
                      <Cards
                      img={IOT}
                      title="Iot & Embedded Systems"
                      desc="We bring forth sophisticated generation next IoT devices into the world. With the infinite potential for this mammoth industry, we render our services of the highest quality."
                      />
                  </div>
                
                </div>
            </div>
        )
    }
}

export default expertise