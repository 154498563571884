import './scss/workflowcards.css'
function WorkflowCards(props){
    return (
        <div className="WCards">
            <div className="wcard-img">
                {props.img}
            </div>
            <div className="wcard-title"><p>{props.title}</p></div>
        </div>
    )
}

export default WorkflowCards;