import React from 'react'
import Logo from '../assets/logo.png'
import Expertise from '../expertise/expertise.js'
import Footer from '../footer/footer'
import Workflow from '../workflow/workflow'
import './scss/index.css'


function index() {
    return (
        <div className="home">
            <div className="why">
                <div className="one">
                    <p className="main-title">Why Confegure?</p>
                    <p className="main-content">
                        Hey there. Looking for exquisite Mobile and Web Development 
                        Applications? You are at the right spot.
                        With the growing traditions of taking businesses online, we're here to take you a step forward. 
                        We excel in varied domains from Servers and Networking assistance to Cloud-based solutions.
                        We are here to cultivate nascent enthusiasts who are looking for guidance in the Industry
                        with Internships and additional perks. To sum up, we can help you in taking your product into the world with means of splendid Digital Marketing Services. 
                        We're Confegure Techsols Pvt.Ltd
                    </p>
                </div>
                <div className="two">
                    <img src={Logo} alt="main-logo"/>
                </div>
            </div>
            <div>
                <Expertise />
            </div>
            <div>
                <Workflow />
            </div>
            {/* <div>
                <Blog/>
            </div> */}
            <div>
                <Footer />
            </div>
        </div>
    )
}

export default index;