import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom'
import Index from './components/home/index.js'
import Header from './components/header/header.js'
import Products from './components/products/products.js';
import Pricing from './components/pricing/pricingpage.js';
import Projects from './components/projects/projects.js';
import PoliciesPage from './components/privacy-policy/policiesPage.js';
import ContactUsPage from './components/contactus/contactus.js';
import About from './components/aboutus/aboutus.js';

function App() {
  return (
    <Router browserHistory>
      <Header />
      <Routes>
        <Route
          exact = "true"
          path="/confegure.com"
          element = {<Navigate to="/home" />}
        />
        <Route exact="true" path="/" element={<Index />} />
        <Route exact="true" path="/home" element={<Index />} />
        <Route exact="true" path="/ourproducts" element={<Products/>} />
        <Route exact="true" path="/ourprojects" element={<Projects/>} />
        <Route exact="true" path="/pricing" element={<Pricing/>} />
        <Route exact="true" path="/aboutus" element={<About/>} />
        <Route exact="true" path="/contactus" element={<ContactUsPage/>} />
        <Route path="/policies" element={<PoliciesPage/>} />
      </Routes>
    </Router>
  );
}

export default App;
