import React from 'react'
import './scss/header.css'
import { NavLink } from 'react-router-dom'
import { GrFacebookOption, GrLinkedin, GrYoutube, GrInstagram, GrMedium, GrTwitter, GrApps, GrClose } from "react-icons/gr";
import Logo from '../assets/logo.png'




function Header() {

    function menu() {
        var x = document.getElementById("dropheader");
        var x1 = document.getElementById("hamburger");
        var x2 = document.getElementById("hamburger-close");
        var y = document.getElementById("social-icons");
        if(window.innerWidth<1200){
            if (x.style.display === "block") {
                x.style.display = "none";
                // y.style.display = "none";
                x2.style.display = "none";
                x1.style.display = "block";
            } else {
                x.style.display = "block";
                // y.style.display = "block";
                x2.style.display = "block";
                x1.style.display = "none";
            }
        }
    }
    return (
        <div>
            <div className="main-menu">
                <div className="logo">
                    <a href="/"><img src={Logo} /></a>
                </div>
                <div className="header">
                    <li><NavLink exact="true" activeclassname="active" to="/home">Home</NavLink></li>
                    <li><NavLink activeclassname="active" to="/ourproducts">Products</NavLink></li>
                    <li><NavLink activeclassname="active" to="/ourprojects">Projects</NavLink></li>
                    <li><NavLink activeclassname="active" to="/pricing">Pricing</NavLink></li>
                    <li><NavLink activeclassname="active" to="/aboutus">About Us</NavLink></li>
                    <li><NavLink activeclassname="active" to="/contactus">Contact Us</NavLink></li>
                </div>

                <div id="hamburger" className="hamburger">
                    <a><GrApps onClick={menu} size={20} /></a>
                </div>
                <div id="hamburger-close" className="hamburger-close">
                    <a><GrClose onClick={menu} size={20} /></a>
                </div>
                <div className="social-icons">
                    <a href="https://www.linkedin.com/company/confegure" target="_blank"><GrLinkedin size={25} /></a>
                    <a href="https://www.instagram.com/confegure" target="_blank">
                        <GrInstagram size={25} />
                    </a>

                    <a href="https://www.facebook.com/people/Confegure/100069006813589/" target="_blank"><GrFacebookOption size={25} /></a>
                    <a href="https://twitter.com/tic_trac" target="_blank"><GrTwitter size={25} /></a>
                    {/* <a href="#"><GrMedium size={25} /></a>
                    <a href="#"><GrYoutube size={25} /></a> */}
                </div>
            </div>

            <div id="drop" className="drop">
                <div id="dropheader" className="dropheader">
                    <li><NavLink exact="true" activeclassname="active" to="/">Home</NavLink></li>
                    <li><NavLink activeclassname="active" to="/ourproducts">Our Products</NavLink></li>
                    <li><NavLink activeclassname="active" to="/ourprojects">Our Projects</NavLink></li>
                    <li><NavLink activeclassname="active" to="/aboutus">About Us</NavLink></li>
                </div>
                <div className="sicons">
                    <div id="social-icons" className="social-icons">
                    <a href="https://www.linkedin.com/company/confegure" target="_blank"><GrLinkedin size={25} /></a>
                    <a href="https://www.instagram.com/confegure" target="_blank">
                        <GrInstagram size={25} />
                    </a>

                    <a href="https://www.facebook.com/people/Confegure/100069006813589/" target="_blank"><GrFacebookOption size={25} /></a>
                    <a href="https://twitter.com/tic_trac" target="_blank"><GrTwitter size={25} /></a>
                    {/* <a href="#"><GrMedium size={25} /></a>
                    <a href="#"><GrYoutube size={25} /></a> */}
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Header;