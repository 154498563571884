import React,{useEffect} from 'react'
import ProdCard1 from '../cards/product-card-1'
// import ProdCard1 from '../cards/product-card-2'
import '../products/scss/products.css'
import Web from '../assets/logo header.png'
import TicTracLogo from '../assets/products/tictrac_logo.png'
import ReachAlertLogo from '../assets/products/reach_alert_logo.png'
import OKNextLogo from '../assets/products/oknext_logo.png'
import Footer from '../footer/footer'



function Products()
{
    function menu() {
        var x = document.getElementById("dropheader");
        var x1 = document.getElementById("hamburger");
        var x2 = document.getElementById("hamburger-close");
        var y = document.getElementById("social-icons");
        if(window.innerWidth<1200){
            if (x.style.display === "block") {
                x.style.display = "none";
                // y.style.display = "none";
                x2.style.display = "none";
                x1.style.display = "block";
            } else {
                x.style.display = "block";
                // y.style.display = "block";
                x2.style.display = "block";
                x1.style.display = "none";
            }
        }
    }

    useEffect(()=>{
        menu()
    },[])


    return(
        <div className="products-main">
            <div className="prod-main-title">
                <p>Our Products</p>
            </div>
            <div className="prod-cards-grid">
           <ProdCard1
            title="Tic Trac"
            description = "Planning to watch a movie at your comfortable place, favourite theatre and time? Get an alarm as soon as bookings start and buy your tickets."
            image = {TicTracLogo}
            link = "https://bit.ly/tcTrc"
           /> 
           <ProdCard1
            title="Reach Alert"
            description = "A Pleasant Nap and a Reliable Partner is what you need for a Peaceful Travel.Reach Alert hands you both of them with just few taps."
            image = {ReachAlertLogo}
            link = "https://bit.ly/Reach-Alert"
           />
           <ProdCard1
            title="Ok! Next"
            description = "Type anything you want to listen with a gap between word/words. Listen to that text sequentially by saying OK/NEXT/YES."
            image = {OKNextLogo}
           /> 
           <ProdCard1
           title="E-Commerce Application"
           description = "Electronic commerce or e-commerce (sometimes written as eCommerce) is a business model that lets firms and individuals buy and sell things over the internet. E-commerce operates in all four of the following major market segments: ... Business to consumer. Consumer to consumer."
           image = {Web}
           />
           <ProdCard1
            title="Human Resource Management System"
            description = "An HRMS, or human resources management system, is a suite of software applications used to manage human resources and related processes throughout the employee lifecycle."
            image = {Web}
           /> 
           <ProdCard1
            title="University Management System / School Management System"
            description = "University management system (UMS) is a web-based solution which covers all aspects of colleges, universities and schools. ... It is developed for conducting, monitoring & analyzing complex activities of the University and its affiliated colleges like Centralized Admission, Centralized Examination, and much more."
            image = {Web}
           />
           <ProdCard1
           title="Facility Management System"
           description = "Facilities management can be defined as the tools and services that support the functionality, safety, and sustainability of buildings, grounds, infrastructure, and real estate."
           image = {Web}
           /></div>
           <div>
           <Footer/>
           </div>
        </div>
        
    )
}

export default Products;