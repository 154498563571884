import './scss/about-us-cards.css'
import {GrFacebook, GrInstagram, GrLinkedin} from 'react-icons/gr'

function ACards(props){
    return (
        
                <div className="ACards">
                    <div className="Acard-img"><img src={props.img}/></div>
                    <div className="Acard-name"><p>{props.name}</p></div>
                    <div className="Acard-desg"><p>{props.desg}</p></div>
                    <div className="social-icons">
                        <a href={props.linkedin}target="_blank"><GrLinkedin size={20} /></a>
                    </div>
                </div>
            
    )
}

export default ACards;