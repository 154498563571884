import React from 'react'
import '../cards/scss/product-card-1.css'
 

function ProdCard1(props)
{
    return(
        <div className="prod-card">
            <a href={props.link ?? "/"} target="_blank">
                <div className="image-cont">
                    <img src={props.image} alt={props.title}></img>
                </div>
                <div className="content">
                    <h3 className="title">{props.title}</h3>
                    <p className="description">{props.description}</p>
                </div>
            </a>
        </div>
    )
}

export default ProdCard1