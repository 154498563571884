// src/pages/ContactUsPage.js
import React from 'react';
import './contactus.css';
import Footer from '../footer/footer';

function ContactUsPage() {
  return (
    <div>
    <div className="contact-us-container">
      <h1>Contact Us</h1>
      <div className="contact-details">
        <p><strong>Email:</strong> <a href="mailto:confegure@gmail.com">confegure@gmail.com</a></p>
        <p><strong>Phone:</strong> <a href="tel:+919100902791">+91 9100903791</a></p>
        <p><strong>Address:</strong></p>
        <p>Confegure Techsols Private Limited</p>
        <p>12-80/2, Srinagar Colony, Patancheru</p>
        <p>Hyderabad, India</p>
        <p>502319</p>
      </div>
    </div>
    
    <div><Footer/></div></div>
  );
}

export default ContactUsPage;
